import React from "react";

const DynamicImages = (props) => {
  const { url, style } = props;
  const mainRoute = "https://api.excelist.am/api/v1/public";
  // const mainRoute = "http://localhost:5000/api/v1/public";
  // const mainRoute = "http://143.244.156.125:5000/api/v1/public";
  return <img src={`${mainRoute}${url}`} style={style} alt="some caption" />;
};

export default DynamicImages;
