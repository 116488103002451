import React from "react";
import { connect } from "react-redux";
import { Input, message, Form } from "antd";
import { createComment } from "../../store/api";
import { CREATE_COMMENT } from "../../store/actionTypes";
import { ActionCreator, POST } from "../../store/actionCreators";
import parseDate from "../functions/parseTime";

const { TextArea } = Input;
class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      name: "",
      email: "",
      parentId: this.props.parentId,
      parentType: this.props.parentType,
      parentTitle: this.props.parentTitle,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  executeScroll = (parentCommentId) => {
    console.log(parentCommentId, 'parentCommentId')
    const formElement = document.getElementById('form_reply')
    const commentContext = document.getElementById('comment-context')
    commentContext.focus()
    formElement.scrollIntoView({ behavior: 'smooth' })
    this.setState({ parentCommentId })

  }


  handleSubmit = async () => {
    const link = window?.location.href
    const {
      comment,
      name,
      email,
      parentId,
      parentType,
      parentCommentId,
      parentTitle,
    } = this.state;
    const { dispatch } = this.props;
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var valid = re.test(email);

    if (!valid) {
      message.error("Ոչ ճիշտ էլ․ հասցե");
      return false;
    }
    if (!comment || !name || !email) {
      message.error("Խնդրում ենք լրացրեք բոլոր դաշտերը");
      return false;
    }
    const data = { comment, name, email, parentId, parentType, parentTitle, link, parentCommentId };
    const response = await dispatch(POST(createComment, data));
    if (response.code === 200) {
      message.success("Մեկնաբանությունը հաջողությամբ ավելացվել է");
      await dispatch(ActionCreator(CREATE_COMMENT, response.data));
      this.setState({ comment: "", name: "", email: "", parentCommentId: null });
    } else {
      message.error({ content: "Ինչ որ բան գնաց ոչ այնպես" });
    }
  };

  render() {
    const { Comments } = this.props;
    const { parentId, parentCommentId } = this.state;
    const CurrentComments =
      Comments && Comments.filter((item, key) => item.parentId === parentId && !item.parentCommentId);

    const selectedRelyComment = CurrentComments && CurrentComments.filter((comm) => comm._id === parentCommentId);

    const subComments =  Comments && Comments.filter((comm) => comm.parentCommentId);
    console.log(subComments, 'sdsd')

    console.log(selectedRelyComment, 's')
    return (
      <div className="commentbox" id='form_reply'>
        <p className="commentbox__title">Մեկնաբանել</p>
        <p className="commentbox__subtitle">Ձեր էլ. փոստը չի հրապարակվելու</p>
        {
          parentCommentId && selectedRelyComment.length && (
              <>
                <div className='reply-comment-head'>
                  &nbsp;&nbsp;<b>{selectedRelyComment[0].name}</b>&nbsp;&nbsp;&nbsp;
                  <span>{selectedRelyComment[0].comment}</span>
                </div>
                <br/>
              </>
          )
        }
        <Form className="commentbox__form">
          <Form.Item>
            <TextArea
              rows={5}
              placeholder="Մեկնաբանություն"
              name="comment"
              onChange={this.handleChange}
              value={this.state.comment}
              id="comment-context"
            />
          </Form.Item>
          <Form.Item className="commentbox__form__secondline">
            <Input
              name="name"
              placeholder="Անուն"
              onChange={this.handleChange}
              className="commentbox__form__input"
              value={this.state.name}
            />
            <Input
              name="email"
              placeholder="Էլ. փոստ"
              onChange={this.handleChange}
              className="commentbox__form__input"
              value={this.state.email}
            />
            <button
              className="commentbox__form__button"
              onClick={this.handleSubmit}
            >
              Մեկնաբանել
            </button>
          </Form.Item>
        </Form>
        <div className="commentbox__comments">
          <p className="commentbox__comments__title">
            {CurrentComments && CurrentComments.length} Comments
          </p>
          {CurrentComments &&
            CurrentComments.map((comment, key) => {
              return (
                <div>
                  <div key={key} className="commentbox__comments__comment">
                    <img
                      alt="avatar"
                      src="https://secure.gravatar.com/avatar/c282d7320b0178dec2a637ba27ed2912?s=90&amp;d=mm&amp;r=g"
                    />
                    <div className="commentbox__comments__comment__content">
                      <p className="commentbox__comments__comment__content__name">
                        {comment.name}{" "}
                        <span className="commentbox__comments__comment__content__date">
                          {parseDate(comment.createdAt)}
                        </span>
                        <button onClick={() => this.executeScroll(comment._id)}>reply</button>
                      </p>
                      <p className="commentbox__comments__comment__content__data">
                        {comment.comment}
                      </p>
                    </div>
                  </div>
                  {
                    subComments && subComments.filter(sC => sC.parentCommentId === comment._id).map(sComm => (
                        <div key={key} className="commentbox__comments__comment-replay">
                          <img
                              alt="avatar"
                              src="https://secure.gravatar.com/avatar/c282d7320b0178dec2a637ba27ed2912?s=90&amp;d=mm&amp;r=g"
                          />
                          <div className="commentbox__comments__comment__content">
                            <p className="commentbox__comments__comment__content__name">
                              {sComm.name}{" "}
                              <span className="commentbox__comments__comment__content__date">
                              {parseDate(sComm.createdAt)}
                              {/*<button onClick={() => this.executeScroll(comment._id)}>reply</button>*/}
                        </span>
                            </p>
                            <p className="commentbox__comments__comment__content__data">
                              {sComm.comment}
                            </p>
                          </div>
                        </div>
                    ))
                  }
                </div>
              );
            })}
        </div>
      </div>
    );
  }

}

const get = (state) => {
  return { Comments: state.Comments };
};

export default connect(get)(Comments);
