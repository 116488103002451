//Lessons
export const GET_ALL_LESSONS = "GET_ALL_LESSONS";
export const DELETE_LESSON = "DELETE_LESSON";
export const UPDATE_LESSON = "UPDATE_LESSON";
export const CREATE_LESSON = "CREATE_LESSON";

//Feedbacks
export const GET_ALL_FEEDBACKS = "GET_ALL_FEEDBACKS";
export const DELETE_FEEDBACK = "DELETE_FEEDBACKS";
export const UPDATE_FEEDBACK = "UPDATE_FEEDBACKS";
export const CREATE_FEEDBACK = "CREATE_FEEDBACKS";

//Videoblogs
export const GET_ALL_VIDEOBLOGS = "GET_ALL_VIDEOBLOGS";
export const DELETE_VIDEOBLOG = "DELETE_VIDEOBLOG";
export const UPDATE_VIDEOBLOG = "UPDATE_VIDEOBLOG";
export const CREATE_VIDEOBLOG = "CREATE_VIDEOBLOG";

// Sponsor
export const GET_ALL_SPONSOR = "GET_ALL_SPONSOR";
export const DELETE_SPONSOR = "DELETE_SPONSOR";
export const CREATE_SPONSOR = "CREATE_SPONSOR";

//Blogs
export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const DELETE_BLOG = "DELETE_BLOG";
export const UPDATE_BLOG = "UPDATE_BLOG";
export const CREATE_BLOG = "CREATE_BLOG";
export const ADD_BLOGS = "ADD_BLOGS";

//best
export const GET_ALL_BEST = "GET_ALL_BEST";
export const DELETE_BEST = "DELETE_BEST";
export const UPDATE_BEST = "UPDATE_BEST";
export const CREATE_BEST = "CREATE_BEST";
export const ADD_BEST = "ADD_BEST";

//Courses
export const GET_ALL_COURSES = "GET_ALL_COURSES";
export const DELETE_COURSE = "DELETE_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const CREATE_COURSE = "CREATE_COURSE";

//Single datas
export const GET_ALL_SINGLE_DATA = "GET_ALL_SINGLE_DATA";
export const UPDATE_SINGLE_DATA = "UPDATE_SINGLE_DATA";

export const GET_ALL_SITE_INFO = "GET_ALL_SITE_INFO";
export const UPDATE_SITE_INFO = "UPDATE_SITE_INFO";

//Comments
export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const DELETE_COMMENT = "DELETE_COMMENT";
// export const UPDATE_COURSE = 'UPDATE_COURSE';
export const CREATE_COMMENT = "CREATE_COMMENT";

//Albums
export const GET_ALL_ALBUMS = "GET_ALBUMS";
export const DELETE_ALBUM = "DELETE_ALBUM";
export const UPDATE_ALBUM = "UPDATE_ALBUM";
export const CREATE_ALBUM = "CREATE_ALBUM";

//Subscribers
export const GET_SUBSCRIBERS = "GET_SUBSCRIBERS";
export const DELETE_SUBSCRIBER = "DELETE_SUBSCRIBER";
export const CREATE_SUBSCRIBER = "CREATE_SUBSCRIBER";

//Certificates
export const GET_ALL_CERTIFICATES = "GET_ALL_CERTIFICATES";
export const DELETE_CERTIFICATE = "DELETE_CERTIFICATE";
export const UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE";
export const CREATE_CERTIFICATE = "CREATE_CERTIFICATE";
export const ADD_CERTIFICATE = "ADD_CERTIFICATE";
