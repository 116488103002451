import React from "react";

class RegisteredUsers extends React.Component {
  render(){
    return(
      <>
      Registered users
      </>
    )
  }
}

export default RegisteredUsers;
